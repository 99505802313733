.brandpush-trust-badge {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    min-height: 180px;
    padding-bottom: 12px;
    text-align: center;
  }
  
  .brandpush-title-container {
    padding: 10px 0;
  }
  
  .brandpush-title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 8px;
    color: #0e0e0e;
  }
  
  .brandpush-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .brandpush-leaf {
    position: absolute;
    height: 110px;
    opacity: 0.2;
  }
  
  .mirrored {
    transform: scaleX(-1);
  }
  
  .brandpush-news-logo {
    max-width: 100px;
    margin: 7px 10px;
  }
  
  .brandpush-footer {
    font-size: 10px;
    color: #717171;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  
  .brandpush-verified-icon {
    width: 12px;
    margin-right: 3px;
  }
  